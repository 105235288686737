

export class WorklistFilters {
    constructor(params) {
        this.filterByAssigned = params?.filterByAssigned || []
        this.filterByStatus = params?.filterByStatus || []
        this.filterByFacility = params?.filterByFacility || []
        this.filterByPayer = params?.filterByPayer || []
        this.filterByProvider = params?.filterByProvider || [] 
        this.filterBySubmissionMethod = params?.filterBySubmissionMethod || []
        this.filterBySubmissionStatus = params?.filterBySubmissionStatus || [] 
        this.procedureDateRange = params?.procedureDateRange || []
        this.createdDateRange = params?.createdDateRange || []
        this.followUpDateRange = params?.followUpDateRange || [] 
        this.filterByDepartments = params?.filterByDepartments || [] 
        this.procedureDateRangeNullDatesIncluded = params?.procedureDateRangeNullDatesIncluded || []
        this.createdDateRangeNullDatesIncluded = params?.createdDateRangeNullDatesIncluded || []
        this.followUpDateRangeNullDatesIncluded = params?.followUpDateRangeNullDatesIncluded || []
        this.procedureDateRangeDisplayWithDaysInput = params?.procedureDateRangeDisplayWithDaysInput || []
        this.createdDateRangeDisplayWithDaysInput = params?.createdDateRangeDisplayWithDaysInput || []
        this.followUpDateRangeDisplayWithDaysInput = params?.followUpDateRangeDisplayWithDaysInput || []
        this.procedureDateRangeWithinNDays = params?.procedureDateRangeWithinNDays || []
        this.createdDateRangeWithinNDays = params?.createdDateRangeWithinNDays || []
        this.followUpDateRangeWithinNDays = params?.followUpDateRangeWithinNDays || []
        this.filterByMrn = params?.filterByMrn || ''
        this.filterByPatientFirstName = params?.filterByPatientFirstName || ''
        this.filterByPatientLastName = params?.filterByPatientLastName || ''
        this.filterByEmrId = params?.filterByEmrId || ''
        this.filterByCptCode = params?.filterByCptCode || ''
        this.filterByProcedureDescription = params?.filterByProcedureDescription || ''
        this.isDefaultFilter = params?.isDefaultFilter || false
    }
}

export class WorklistFilter {
    constructor(params) {
        this.worklistFilterId = params?.worklistFilterId
        this.name = params?.name
        this.filter = params?.filter
        this.departmentId = params?.departmentId
        this.isDefault = params?.isDefault
        this.paPersonId = params?.paPersonId
    }
}

export class Sort {
    constructor(params) {
        this.field = params?.field
        this.direction = params?.direction
    }
}

export class WorklistColumnHeader {
    constructor(params) {
        this.state = params?.state // => ACTIVE - READY_TO_DEACTIVATE - DEACTIVATED
        this.field = params?.field
    }
}

export class Filter {
    constructor(params) {
        this.Field = params?.field,
        this.Value = params?.value
        this.Operator = params?.operator
    }
}

export class SearchTerm {
    constructor(params) {
        this.filterKey = params?.filterKey || ''
        this.narrowSearchBy = params?.narrowSearchBy || 'all'
        this.filter = new PaRequestFiltersViewModal({
            logic: null,
            filters: params?.filter,
        })
    }
}

export class PaRequestFiltersViewModal {
    constructor(params) {
        this.logic = params?.logic
        this.filters = params?.filters || []
    }
}