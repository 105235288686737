<template>
  <div
    v-if="isShown"
    class="newVersionMessage animate__animated animate__fadeInDown">
    <img src="/images/new-version.svg" alt="" aria-hidden="true" />
    <span>
      Pathway has been updated to a new version, your page will automatically refresh 
      <span v-if="uploadProgress < 1">
        in
        <b>{{ refreshInMilliseconds | durationFromNow }}</b>.</span>
      <span v-if="uploadProgress >= 1">when uploads complete. </span>
      <br />
      <span v-if="uploadProgress < 1">
        Or get the latest version now:
        <a ref="refreshButton" href="#" @click.prevent="refresh">REFRESH</a>
      </span>
      <span v-if="uploadProgress >= 1">&nbsp;</span>
    </span>
  </div>
</template>

<script>
    import { mapState } from 'vuex'
    import ENV_CONFIG from '../config/environment'
    import logger from '@/shared/logger'
    import axios from 'axios'

export default {
    name: 'NewVersionModal',
    data() {
      return {
        intervalId: null,
        refreshInMilliseconds: 120000,
        countdownInterval: 1000,
        checkVersionInterval: 600000,
        version: null,
        isShown: false,
        waitingForDocumentsToUpload: false,
      }
    },
    computed: {
        ...mapState({
            uploadProgress: ['uploadProgress']
        })
    },
    mounted() {
      this.checkVersion()
      
      this.intervalId = setInterval(
        () => this.checkVersion(),
        this.checkVersionInterval)
    },
    destroyed() {
      clearInterval(this.intervalId)
    },
    watch: {
      uploadInProgress(value) {
        if (!value && this.waitingForDocumentsToUpload) {
          this.refresh()
        }
      },
    },
    methods: {
      refresh() {
        window.location.reload(true)
      },
      checkVersion() {
        axios.get(`${ENV_CONFIG.env_api}Version/GetUIVersion`, {
        }).then((response) => {
            if(!this.version) {
                this.version = response.data
                return
            }
            if(this.version !== response.data) {
                this.version = response.data
                this.display()
                logger.logShowNewVersionMessage()
            }
        })
      },
      display() {
        this.isShown = true
        clearInterval(this.intervalId)
        this.intervalId = setInterval(
          () => {
            this.refreshInMilliseconds -= this.countdownInterval
            if (this.refreshInMilliseconds <= 0) {
              if (this.uploadProgress >= 1) {
                this.waitingForDocumentsToUpload = true
                return
              }
              this.refresh()
            }
          },
          this.countdownInterval)
      },
    },
  }
</script>

